import { Modal } from '@consta/uikit/Modal';
import { ColumnDirective, ColumnsDirective, getCellIndexes, RangeDirective, RangesDirective, SheetDirective, SheetsDirective, SpreadsheetComponent } from "@syncfusion/ej2-react-spreadsheet";
import { Button, DateBox, LoadPanel, NumberBox, Popup, SelectBox, TextArea } from "devextreme-react";
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';
import notify from "devextreme/ui/notify";
import { observer } from 'mobx-react';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { alert } from './dialogs';
import mainStore from './store';

import '../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-grids/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-react-spreadsheet/styles/bootstrap5.css';
import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';

const ControlWidth = "180px";

let defData = [
    { "№ п/п": 1, "Артикул": "A000", "Наименование товара": "Не определено", "Цена": 0.0, "Кол-во": 1, "Скидка": 0.0, "Ед.": "шт", "Сумма": 0.0 },
    // { A: "1", B: "000", C: "Не определено", D: 0.0, E: "1", F: 0.0, G: "шт", H: 0.0 },
];



const Naklad = (props) => {
    const { visible, onClose, title, ownerStatusCode, nakladId, ownerBuyerId, selectedRowKeys } = props;

    const inputRef = useRef(null);
    const edSourceRef = useRef();
    const edBuyerRef = useRef();
    const edFromWareRef = useRef();
    const edToWareRef = useRef();
    const spreadsheetRef = useRef();

    const [sheet, setSheet] = useState();
    const [sheetSource, setSheetSource] = useState([]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [fileName, setFileName] = useState();
    const [statusCode, setStatusCode] = useState(ownerStatusCode);
    const [dateCreate, setDateCreate] = useState(new Date());
    const [sourceId, setSourceId] = useState();
    const [buyerId, setBuyerId] = useState();
    const [fromWarehouseId, setFromWarehouseId] = useState();
    const [toWarehouseId, setToWarehouseId] = useState();
    const [loading, setLoading] = useState(false);

    const [dxData, setDxData] = useState(defData);

    const [colArticul, setColArticul] = useState(2);
    const [colGoods, setColGoods] = useState(3);
    const [colPrice, setColPrice] = useState(4);
    const [colCount, setColCount] = useState(5);
    const [colDiscount, setColDiscount] = useState(6);
    const [colTotal, setColTotal] = useState(-1);
    const [startRow, setStartRow] = useState(2);
    const [endRow, setEndRow] = useState(2);
    const [comm, setComm] = useState();

    const { statusSource, sourceSource, mainSource, buyerSource, wareHouseSource, ostatokSource } = mainStore;

    const removeBanner = () => {
        let elements = document.querySelectorAll("div");
        for (let i = 0; i < elements.length; i++) {
            if (elements[i].style.zIndex === "999999999" || elements[i].style.zIndex === "99999") {
                elements[i].remove();
                break;
            }
        }
    }

    useEffect(() => {
        setTimeout(removeBanner, 100);
    });

    useEffect(() => {
        setIsModalOpen(visible);
        setBuyerId(["BACK"].includes(ownerStatusCode) ? ownerBuyerId : null);

        setSourceId(null);
        setBuyerId(null);
        setFromWarehouseId(null);
        setToWarehouseId(null);

        setFileName(null);
        setSheetSource([]);

        if (selectedRowKeys && selectedRowKeys.length) {
            defData = [];

            let obj = ostatokSource.find(el => el.Record_ID === selectedRowKeys[0]);
            setFromWarehouseId(obj.Warehouse_ID);

            defData.push({ idx: 1, A: "№ п/п", B: "Артикул", C: "Наименование товара", D: "Цена", E: "Кол-во", F: "Скидка", G: "Ед.", H: "Сумма" });

            selectedRowKeys.forEach((key, i) => {
                obj = ostatokSource.find(el => el.Record_ID === key);
                defData.push({ idx: i + 2, A: i + 1, B: obj.Goods_Art, C: obj.Goods_Name, D: obj.Price, E: obj.Ostatok, F: 0.0, G: "шт", H: 0.0 });
                // console.log(obj);
            });
        }
        // setDxCols(defCols);
        setDxData(defData);

        setStatusCode(ownerStatusCode);
        setCommentFromStatus(ownerStatusCode);
        // console.log(selectedRowKeys);
    }, [visible]);

    const sheetChanged = e => {
        setSheet(e.value);
        spreadsheetRef.current.activeSheetIndex = (e.value - 1);
        // loadSheet(e.value);
    }

    const readFileAsBuffer = async (file) => {
        let result = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsArrayBuffer(file);
        });
        return result;
    }

    const handleFileChange = async (e) => {
        const fileObj = e.target.files && e.target.files[0];
        const excelFile = document.getElementById("fileDemo").files[0];
        setFileName(excelFile?.name);

        const data = await readFileAsBuffer(fileObj);

        const file = new File([data], 'Sample.xlsx');
        const spreadsheet = spreadsheetRef.current;
        if (spreadsheet) {
            await spreadsheet.open({ file }); // open the file into Spreadsheet
            // console.log(res);
        }
        // console.log(spreadsheet.getActiveSheet());

        // ExcelRenderer(fileObj, (err, resp) => {
        //     if (err) {
        //         console.log(err);
        //     }
        //     else {
        //         setBookData(resp); // Данные из книги Excel
        //         setSheetSource(resp.map(el => el.sheet)); // Для SelectBox
        //         setSheet(1);
        //     }
        // });
    }

    const loadNaklad = async () => {
        if (!edSourceRef.current.props.disabled && (sourceId === null || sourceId === undefined)) {
            notify({ message: "Не указан поставщик!", position: "center", width: 300 }, "warning");
            return;
        }

        if (!edBuyerRef.current.props.disabled && !buyerId) {
            notify({ message: "Не указан покупатель!", position: "center", width: 300 }, "warning");
            return;
        }

        if (!edFromWareRef.current.props.disabled && (fromWarehouseId === null || fromWarehouseId === undefined)) {
            notify({ message: "Не указан склад-источник!", position: "center", width: 300 }, "warning");
            return;
        }

        if (!edToWareRef.current.props.disabled && (toWarehouseId === null || toWarehouseId === undefined)) {
            notify({ message: "Не указан склад назначения!", position: "center", width: 300 }, "warning");
            return;
        }

        setLoading(true);

        let data = [];

        const rows = spreadsheetRef.current.getActiveSheet().rows;
        for (let i = startRow - 1; i < endRow; i++) {
            let row = {};
            row.statusId = statusSource.find(el => el.Status_Code === statusCode)?.Status_ID;
            row.goodsArt = rows[i].cells[colArticul - 1].value;
            row.goodsName = rows[i].cells[colGoods - 1].value;
            row.cnt = rows[i].cells[colCount - 1].value;
            row.price = colPrice < 0 ? 0 : rows[i].cells[colPrice - 1].value;
            row.discount = colDiscount < 0 ? 0 : rows[i].cells[colDiscount - 1].value;
            row.total = colTotal < 0 ? 0 : rows[i].cells[colTotal - 1].value;
            data.push(row)
        }

        if (data.find(el => !el.goodsArt || !el.goodsName)) {
            setLoading(false);
            await alert("В исходном файле отсутствуют артикул или наименование!");
            return;
        }


        data = {
            naklad: [
                {
                    statusId: statusSource.find(el => el.Status_Code === statusCode)?.Status_ID,
                    dateCreate: moment(dateCreate).format("YYYYMMDD"),
                    comm: comm,
                    paid: 0,
                    sourceId: edSourceRef.current.props.disabled ? null : sourceId,
                    buyerId: edBuyerRef.current.props.disabled ? null : buyerId,
                    warehouseId: !edToWareRef.current.props.disabled ? toWarehouseId : (edFromWareRef.current.props.disabled ? null : fromWarehouseId)
                }
            ],
            goods: data
        }

        const res = await mainStore.insertNaklad(data);

        if (res?.message) {
            setLoading(false);
            await alert(res.message);
            return;
        }

        // console.log("naklad_id: ", res);

        setLoading(false);

        if (res.naklad_id === -1 && res.badData?.length) {
            await alert("Недостаточно товаров на складе:\n" + res.badData.map(e => e.goodsArt).join(", "));
            return;
        }

        setIsModalOpen(false);
        
        await props.refreshData();

        mainStore.setNakladId(res.naklad_id);
    }

    const setCommentFromStatus = (status) => {
        switch (status) {
            case "BACK":
                setComm("Возврат от покупателя");
                break;
            case "IN":
                setComm("Поступление товара");
                break;
            case "MOVE":
                moveWarehouse();
                break;
            case "TOSRC":
                setComm("Возврат товара поставщику");
                break;
            case "BONUS":
                setComm("Бонус поставщика за месяц");
                break;
            case "TRANSOUT":
                setComm("Перевод средств поставщику");
                break;
            case "TRANSIN":
                setComm("Поступление средств от покупателя");
                break;
            case "BAD":
                setComm("Расход в брак");
                break;
            case "SALE":
                setComm("Продажа");
                break;
            default:
                setComm(null);
                break;
        }
    }

    const moveWarehouse = (from, to) => {
        if (!edFromWareRef.current || edFromWareRef.current.props.disabled || edToWareRef.current.props.disabled) return;

        from = from || fromWarehouseId;
        to = to || toWarehouseId;
        const fromWare = wareHouseSource.find(el => el.Warehouse_ID === from)?.Warehouse_Name || "";
        const toWare = wareHouseSource.find(el => el.Warehouse_ID === to)?.Warehouse_Name || "";
        setComm(`Перемещение со склада <${fromWare}> на склад <${toWare}>`);
    }

    return (
        <>
            <Modal
                hasOverlay
                isOpen={isModalOpen}
                // onEsc={() => setIsModalOpen(false)}
                onClose={() => { onClose(); setIsModalOpen(false) }}
                style={{ height: "770px", width: "1300px" }}
            >
                {/* <Popup visible showTitle title="Dialog" /> */}
                <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <div style={{ display: "flex", height: "50px", margin: "0 10px 10px 0", justifyContent: "space-between", alignItems: "center", color: "steelblue", paddingLeft: "10px", fontSize: "22px", fontWeight: 600, borderBottom: "1px groove rgb(241, 232, 232)" }}>
                        {title}
                        <Button stylingMode="text" icon="close" onClick={() => setIsModalOpen(false)} />
                    </div>

                    <div style={{ display: "flex", backgroundColor: "white", flexGrow: 1, flexDirection: "row" }}>
                        <div style={{ display: "flex", backgroundColor: "white", width: "315px", height: "100%" }}>
                            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>

                                <Label style={{ margin: "0px 0 5px 10px", paddingBottom: "2px", fontSize: "15px", fontWeight: 500, borderBottom: "1px groove rgb(241, 232, 232)" }}> Параметры: </Label>
                                <RowItem>
                                    <Label> Дата: </Label>
                                    <DateBox width={ControlWidth} value={dateCreate} onValueChanged={e => setDateCreate(e.value)} />
                                </RowItem>
                                <RowItem>
                                    <Label> Тип: </Label>
                                    <SelectBox
                                        width={ControlWidth}
                                        dataSource={statusSource}
                                        displayExpr="Status_Name"
                                        valueExpr="Status_Code"
                                        value={statusCode}
                                        disabled={!["IN"].includes(ownerStatusCode)}
                                        onValueChanged={e => {
                                            setStatusCode(e.value);
                                            setCommentFromStatus(e.value);
                                        }}
                                    />
                                </RowItem>
                                <RowItem>
                                    <Label> Поставщик: </Label>
                                    <SelectBox
                                        width={ControlWidth}
                                        dataSource={sourceSource}
                                        displayExpr="Source_Name"
                                        valueExpr="Source_ID"
                                        value={sourceId}
                                        ref={edSourceRef}
                                        disabled={!["IN"].includes(statusCode)}
                                        onValueChanged={async (e) => {
                                            setSourceId(e.value);

                                            if (!e.value) return;

                                            // sheet.getRange(1, 3, 1, 7).formatter("# ### ### ##0.00")

                                            if (statusCode === "BONUS") {
                                                const bonus = await mainStore.getBonus(e.value, dateCreate);
                                                const arr = [...dxData];
                                                arr[1].D = bonus;
                                                setDxData(arr);
                                            }
                                            else {
                                                const obj = mainSource.find(el => el.Naklad_ID === nakladId);
                                                if (obj) {
                                                    const arr = [...dxData];
                                                    arr[1].D = obj.Balance;
                                                    setDxData(arr);
                                                }
                                            }
                                        }}
                                    />
                                </RowItem>
                                <RowItem>
                                    <Label> Покупатель: </Label>
                                    <SelectBox
                                        dataSource={buyerSource}
                                        displayExpr="Buyer_Name"
                                        valueExpr="Buyer_ID"
                                        value={buyerId}
                                        ref={edBuyerRef}
                                        onValueChanged={e => setBuyerId(e.value)}
                                        disabled={!["BACK", "SALE", "TOSRC"].includes(statusCode)}
                                        width={ControlWidth}
                                    />
                                </RowItem>
                                <RowItem>
                                    <Label> Склад-источник: </Label>
                                    <SelectBox
                                        width={ControlWidth}
                                        dataSource={wareHouseSource}
                                        displayExpr="Warehouse_Name"
                                        valueExpr="Warehouse_ID"
                                        value={fromWarehouseId}
                                        ref={edFromWareRef}
                                        disabled={!["SALE", "BAD", "MOVE", "TOSRC"].includes(statusCode)}
                                        onValueChanged={e => { setFromWarehouseId(e.value); moveWarehouse(e.value, null); }}
                                    />
                                </RowItem>
                                <RowItem>
                                    <Label> Склад назначения: </Label>
                                    <SelectBox
                                        width={ControlWidth}
                                        dataSource={wareHouseSource}
                                        displayExpr="Warehouse_Name"
                                        valueExpr="Warehouse_ID"
                                        value={toWarehouseId}
                                        ref={edToWareRef}
                                        disabled={!["IN", "BACK", "BAD", "MOVE"].includes(statusCode)}
                                        onValueChanged={e => { setToWarehouseId(e.value); moveWarehouse(null, e.value); }}
                                    />
                                </RowItem>
                                <RowItem>
                                    <Label> Лист Excel: </Label>
                                    <SelectBox
                                        width={ControlWidth}
                                        dataSource={sheetSource}
                                        displayExpr="text"
                                        valueExpr="id"
                                        value={sheet}
                                        onValueChanged={sheetChanged}
                                    />
                                </RowItem>

                                <Label style={{ margin: "20px 0 5px 10px", paddingBottom: "2px", fontSize: "15px", fontWeight: 500, borderBottom: "1px groove rgb(241, 232, 232)" }}> Столбцы: </Label>
                                <RowItem>
                                    <Label> Артикул: </Label>
                                    <NumberBox width={ControlWidth} value={colArticul} onValueChanged={e => setColArticul(e.value)}>
                                        <TextBoxButton name="articul" location='after' options={{
                                            stylingMode: "text", icon: "more", onClick: () => {
                                                const sheet = spreadsheetRef.current.getActiveSheet();
                                                const [r, c] = getCellIndexes(sheet.activeCell);
                                                setColArticul(c + 1);
                                            }
                                        }} />
                                    </NumberBox>
                                </RowItem>
                                <RowItem>
                                    <Label> Товар: </Label>
                                    <NumberBox width={ControlWidth} value={colGoods} onValueChanged={e => setColGoods(e.value)}>
                                        <TextBoxButton name="goods" location='after' options={{
                                            stylingMode: "text", icon: "more", onClick: () => {
                                                const sheet = spreadsheetRef.current.getActiveSheet();
                                                const [r, c] = getCellIndexes(sheet.activeCell);
                                                setColGoods(c + 1);
                                            }
                                        }} />
                                    </NumberBox>
                                </RowItem>
                                <RowItem>
                                    <Label> Цена: </Label>
                                    <NumberBox width={ControlWidth} value={colPrice} onValueChanged={e => setColPrice(e.value)}>
                                        <TextBoxButton name="price" location='after' options={{
                                            stylingMode: "text", icon: "more", onClick: () => {
                                                const sheet = spreadsheetRef.current.getActiveSheet();
                                                const [r, c] = getCellIndexes(sheet.activeCell);
                                                setColPrice(c + 1);
                                            }
                                        }} />
                                    </NumberBox>
                                </RowItem>
                                <RowItem>
                                    <Label> Кол-во: </Label>
                                    <NumberBox width={ControlWidth} value={colCount} onValueChanged={e => setColCount(e.value)}>
                                        <TextBoxButton name="count" location='after' options={{
                                            stylingMode: "text", icon: "more", onClick: () => {
                                                const sheet = spreadsheetRef.current.getActiveSheet();
                                                const [r, c] = getCellIndexes(sheet.activeCell);
                                                setColCount(c + 1);
                                            }
                                        }} />
                                    </NumberBox>
                                </RowItem>
                                <RowItem>
                                    <Label> Скидка: </Label>
                                    <NumberBox width={ControlWidth} value={colDiscount} onValueChanged={e => setColDiscount(e.value)}>
                                        <TextBoxButton name="discount" location='after' options={{
                                            stylingMode: "text", icon: "more", onClick: () => {
                                                const sheet = spreadsheetRef.current.getActiveSheet();
                                                const [r, c] = getCellIndexes(sheet.activeCell);
                                                setColDiscount(c + 1);
                                            }
                                        }} />
                                    </NumberBox>
                                </RowItem>
                                <RowItem>
                                    <Label> Сумма: </Label>
                                    <NumberBox width={ControlWidth} value={colTotal} onValueChanged={e => setColTotal(e.value)}>
                                        <TextBoxButton name="total" location='after' options={{
                                            stylingMode: "text", icon: "more", onClick: () => {
                                                const sheet = spreadsheetRef.current.getActiveSheet();
                                                const [r, c] = getCellIndexes(sheet.activeCell);
                                                setColTotal(c + 1);
                                            }
                                        }} />
                                    </NumberBox>
                                </RowItem>


                                <Label style={{ margin: "20px 0 5px 10px", paddingBottom: "2px", fontSize: "15px", fontWeight: 500, borderBottom: "1px groove rgb(241, 232, 232)" }}> Строки: </Label>
                                <RowItem>
                                    <Label> Начальная: </Label>
                                    <NumberBox width={ControlWidth} value={startRow} onValueChanged={e => setStartRow(e.value)}>
                                        <TextBoxButton name="startRow" location='after' options={{
                                            stylingMode: "text", icon: "more", onClick: () => {
                                                const sheet = spreadsheetRef.current.getActiveSheet();
                                                const [r, c] = getCellIndexes(sheet.activeCell);
                                                setStartRow(r + 1);
                                            }
                                        }} />
                                    </NumberBox>
                                </RowItem>
                                <RowItem>
                                    <Label> Конечная: </Label>
                                    <NumberBox width={ControlWidth} value={endRow} onValueChanged={e => setEndRow(e.value)}>
                                        <TextBoxButton name="endRow" location='after' options={{
                                            stylingMode: "text", icon: "more", onClick: () => {
                                                const sheet = spreadsheetRef.current.getActiveSheet();
                                                const [r, c] = getCellIndexes(sheet.activeCell);
                                                setEndRow(r + 1);
                                            }
                                        }} />
                                    </NumberBox>
                                </RowItem>

                                <RowItem style={{ flexDirection: "column" }}>
                                    <div style={{ justifyContent: "flex-start", width: "100%", marginTop: "10px" }}> Комментарий к накладной: </div>
                                    <TextArea
                                        width="100%"
                                        height="60px"
                                        value={comm}
                                        onValueChanged={e => setComm(e.value)}
                                    />
                                </RowItem>
                            </div>
                        </div>

                        <div style={{ display: "flex", borderLeft: "1px groove rgb(241, 232, 232)", padding: "0 8px 0 3px", width: "900px", maxHeight: "760px", flexDirection: "column", flexGrow: 1 }}>
                            <div style={{ minHeight: "30px", width: "100%" }}>
                                <div style={{ margin: "0 0 10px 5px", display: "flex", flexDirection: "row", alignItems: "center", fontSize: "13px" }}>
                                    <div style={{ minWidth: "80px" }}> Имя файла: </div>
                                    <TextBox value={fileName} width="100%">
                                        <TextBoxButton name="fileOpen" location='after' options={{ hint: "Выбрать файл", stylingMode: "text", icon: "folder", onClick: () => inputRef.current.click() }} />
                                    </TextBox>
                                </div>
                            </div>


                            <SpreadsheetComponent
                                style={{ height: "100%", width: "100%" }}
                                locale="ru-RU"
                                showRibbon={false}
                                showSheetTabs={false}
                                allowOpen
                                allowSave
                                openComplete={e => {
                                    const resp = [...e.response.data.sheets].map((el, i) => { return { text: el.name, id: i + 1 } });
                                    setSheetSource(resp);
                                    setSheet(1);
                                }}
                                ref={spreadsheetRef}
                                saveUrl="https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/save"
                                openUrl="https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/open"
                            >
                                <SheetsDirective>
                                    <SheetDirective>
                                        <RangesDirective>
                                            <RangeDirective dataSource={defData}></RangeDirective>
                                        </RangesDirective>
                                        <ColumnsDirective>
                                            <ColumnDirective width={70}></ColumnDirective>
                                            <ColumnDirective width={120}></ColumnDirective>
                                            <ColumnDirective width={350}></ColumnDirective>
                                            <ColumnDirective width={90}></ColumnDirective>
                                            <ColumnDirective width={60}></ColumnDirective>
                                            <ColumnDirective width={90}></ColumnDirective>
                                            <ColumnDirective width={60}></ColumnDirective>
                                            <ColumnDirective width={80}></ColumnDirective>
                                        </ColumnsDirective>
                                    </SheetDirective>
                                </SheetsDirective>
                            </SpreadsheetComponent>

                        </div>
                    </div>

                    <div style={{ display: "flex", paddingRight: "5px", justifyContent: "flex-end", alignItems: "center", height: "50px", borderTop: "1px groove rgb(241, 232, 232)" }}>
                        <Button stylingMode="contained" text="OK" type="success" height="30px" width="100px" onClick={loadNaklad} />
                        <Button stylingMode='contained' text="Закрыть" height="30px" width="100px" style={{ margin: "0 7px 0 5px" }} onClick={() => setIsModalOpen(false)} />
                    </div>
                </div>

                <input
                    style={{ display: 'none' }}
                    ref={inputRef}
                    id="fileDemo"
                    type="file"
                    accept=".xlsx,.xls"
                    onChange={handleFileChange}
                />

                <LoadPanel
                    visible={loading}
                    showPane
                    shading
                    shadingColor="rgba(0,0,0,0.4)"
                    message="Создание накладной..."
                    onHiding={() => setLoading(false)}
                />
            </Modal>
        </>
    )
}

const Label = styled.div`
    display: flex;
    font-size: 12px;
`;

const RowItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 4px 5px 0 10px;
    font-size: 15px;
`;

export default observer(Naklad);
